import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../images/uaxlogo.svg';

function NavbarComponent() {
  return (
    <Navbar expand="lg" className="">
      <Container>
        <Navbar.Brand href="/"><img src={Logo}/></Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;