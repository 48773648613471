import { useState,useEffect } from 'react';
import axios from 'axios';
import WhiteLoader from '../images/LOADER.gif';
import NoData from '../images/NO_DATA.svg';
const NFTHistory = () => {
    const [loader, setloader] = useState(true);
useEffect(()=>{
    setloader(false)
},[])
return(
            <>
               {loader?
                <div className='' style={{height:"80vh",position:"relative",backgroundColor:"#000"}}>
                <center style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
                <img src={WhiteLoader} style={{width:"3vw"}}/>
                </center>
                </div>
                :      
                <> 
                <div className='dashboard_box_001____ px-4'>
                <div className='' style={{height:"80vh",position:"relative"}}>
                        <center style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
                        <img src={NoData} style={{width:"6vw"}}/>
                        </center>
                        </div>
                </div>
                </>
                }
            </>
)};

export default NFTHistory;
